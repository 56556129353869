import { laptopMediumMax, mobileXLargeMax, mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';
import Button from '../buttons/Button';
import { SliderButton } from '../property/SimilarProperties.style';
import { NewsBox } from './ContentHubArticleBox.style';
import { MobileViewMore as MobileViewMoreGrid } from './ContentHubGrid.style';
import { ContentHubContent } from './ContentHubPageContainer.style';

export const ContentHubCarouselContainer = styled.div`
  padding: 60px 0px;
  background-color: ${({ theme }) => theme.primaryTranslucent};

  ${ContentHubContent} {
    @media (${mobileXXLargeMax}) {
      .view-more {
        display: none;
      }
    }
  }
`;

export const Title = styled.h2`
  font-size: 30px;
  color: ${({ theme }) => theme.textDark};

  @media (${laptopMediumMax}) {
    font-size: 26px;
  }
`;

export const ViewMore = styled(Button)`
  width: 140px;
  border-radius: 8px;
`;

export const CarouselContent = styled(ContentHubContent)`
  margin-top: 15px;
  padding: 0;

  .swiper {
    margin: 20px 0px;
    padding: 10px 0px;
  }

  .swiper-slide {
    display: flex;
    width: calc(100% / 3);
    min-width: 350px;
    height: auto;
    padding: 0 20px 0px;
  }

  ${NewsBox} {
    width: 100%;
  }

  @media (${laptopMediumMax}) {
    .swiper-slide {
      padding: 0 15px 0px;
    }
  }

  @media (${mobileXLargeMax}) {
    .swiper-slide {
      min-width: 320px;
    }
  }
`;

export const SliderBtn = styled(SliderButton)`
  background-color: rgba(255, 255, 255, 0.6);

  svg {
    color: ${({ theme }) => theme.primary};
  }
`;

export const MobileViewMore = styled(MobileViewMoreGrid)`
  padding: 0px 15px;
  margin-top: 0px;
`;
