import { tabletMax, mobileXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import faqHeader from '@propertypal/shared/src/resources/bg/faq-header.jpg';
import styled, { css } from 'styled-components';
import { Box } from '../layout';

export const ContentHubContainer = styled.section`
  background: ${({ theme }) => theme.backgroundLightest};
  padding-bottom: 40px;
`;

export const ContentHubHeaderBg = styled.div<{
  url?: string;
  tabletUrl?: string;
  mobileUrl?: string;
  bgPositionX?: string | null;
  bgPositionY?: string | null;
  tabletBgPositionX?: string | null;
  tabletBgPositionY?: string | null;
  mobileBgPositionX?: string | null;
  mobileBgPositionY?: string | null;
}>`
  background-image: url(${({ url, tabletUrl, mobileUrl }) => url || tabletUrl || mobileUrl || faqHeader.src});
  background-size: cover;
  background-position: ${({ bgPositionX, bgPositionY }) => `${bgPositionX || '50%'} ${bgPositionY || '50%'}`};
  background-repeat: no-repeat;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (${tabletMax}) {
    background-image: url(${({ url, tabletUrl, mobileUrl }) => tabletUrl || url || mobileUrl || faqHeader.src});
    ${({ tabletBgPositionX, tabletBgPositionY, bgPositionX, bgPositionY }) =>
      tabletBgPositionX || tabletBgPositionY
        ? css`
            background-position: ${`${tabletBgPositionX || bgPositionX || '50%'} ${
              tabletBgPositionY || bgPositionY || '50%'
            }`};
          `
        : ''}
  }

  @media (${mobileXLargeMax}) {
    background-image: url(${({ url, tabletUrl, mobileUrl }) => mobileUrl || tabletUrl || url || faqHeader.src});
    ${({ mobileBgPositionX, mobileBgPositionY, tabletBgPositionX, tabletBgPositionY, bgPositionX, bgPositionY }) =>
      mobileBgPositionX || mobileBgPositionY
        ? css`
            background-position: ${`${mobileBgPositionX || tabletBgPositionX || bgPositionX || '50%'} ${
              mobileBgPositionY || tabletBgPositionY || bgPositionY || '50%'
            }`};
          `
        : ''}
  }
`;

export const ContentHubContent = styled(Box)`
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
  padding: 0 20px;
  align-items: center;
  position: relative;
`;
