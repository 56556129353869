import {
  laptopMediumMax,
  laptopMediumMin,
  mobileXLargeMax,
  mobileXLargeMin,
  mobileXXLargeMax,
  mobileXXLargeMin,
  tabletMax,
} from '@propertypal/shared/src/constants/mediaQueries';
import DoorIllustrationGraphic from '@propertypal/shared/src/resources/bg/content-hub-door.svg?url';
import styled, { css } from 'styled-components';
import { NewsBox, NewsContent, NewsImage } from './ContentHubArticleBox.style';
import { ContentHubContent } from './ContentHubPageContainer.style';

export const ContentHubGridContainer = styled.div<{ illustration?: boolean }>`
  padding: 60px 0px;
  position: relative;

  ${({ illustration }) =>
    illustration
      ? css`
          padding-bottom: 350px;
        `
      : ''}

  ${ContentHubContent} {
    @media (${mobileXXLargeMax}) {
      .view-more {
        display: none;
      }
    }
  }
`;

export const ContentHubGridContent = styled(ContentHubContent)`
  margin-top: 20px;
`;

const SmallArticle = css`
  ${NewsBox} {
    display: flex;

    .article-text {
      display: none;
    }
  }

  ${NewsImage} {
    height: 100%;
    width: 100%;
  }

  @media (${mobileXLargeMin}) {
    ${NewsImage} {
      flex: 0 0 230px;
    }
  }
`;

export const ArticleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 15px;
  width: 100%;

  @media (${tabletMax}) {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 40px;
  }
`;

export const ArticleItem = styled.div`
  ${NewsContent} h3 {
    font-size: 16px;
  }

  @media (${laptopMediumMin}) {
    &:first-child {
      grid-area: 4 / 1 / 1 / 1;

      ${NewsImage} {
        height: 280px;
      }

      ${NewsContent} {
        height: calc(100% - 280px);

        & > div {
          gap: 0px;
        }
      }
    }

    &:not(:first-child) {
      ${SmallArticle}

      ${NewsImage} {
        max-height: 150px;
      }

      ${NewsContent} {
        height: auto;
      }
    }
  }

  @media (${laptopMediumMax}) {
    ${SmallArticle}

    ${NewsContent} {
      height: auto;
      flex: 1;
    }

    ${NewsImage} {
      max-width: 150px;
    }
  }

  @media (${laptopMediumMax}) and (${mobileXLargeMin}) {
    ${NewsImage} {
      flex: 150px;
    }
  }

  @media (${tabletMax}) and (${mobileXLargeMin}) {
    ${NewsImage} {
      max-width: 230px;
      flex: 230px;
    }
  }

  @media (${tabletMax}) {
    ${NewsBox} {
      .article-text {
        display: -webkit-box;
      }
    }
  }

  @media (${mobileXLargeMax}) {
    ${NewsBox} {
      flex-direction: column;
    }

    ${NewsImage} {
      width: 100%;
      height: auto;
      max-width: none;
    }
  }

  ${NewsBox} {
    width: 100%;
    height: 100%;
  }
`;

export const MobileViewMore = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  @media (${mobileXXLargeMin}) {
    display: none;
  }
`;

export const IllustrationContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 287px;
`;

export const DoorIllustration = styled.div`
  height: 287px;
  background-image: url(${DoorIllustrationGraphic.src});
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto;
  width: 100%;
`;
